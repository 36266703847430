import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../common/Link';

const Card = ({ image, title, children, button, reverse }) => {
  const hasButton = !!button;

  const flexClass = reverse
    ? 'flex-col-reverse lg:flex-row-reverse'
    : 'flex-col-reverse lg:flex-row';

  return (
    <div className="w-full bg-apricot-100 rounded-3xl self-center lg:self-stretch p-4 lg:p-12 mr-0 lg:mr-6 mb-6 text-white">
      <div className={`flex ${flexClass} items-center h-full`}>
        <div
          className={`flex flex-col ${
            reverse ? 'pt-6 lg:pt-0 lg:pl-16' : 'pt-6 lg:pt-0 lg:pr-16'
          }`}
        >
          <h2 className="text-apricot-800">{title}.</h2>
          <div className="text-secondary-600 text-lg pt-8">{children}</div>
          {hasButton ? <div className="pt-8">{button}</div> : null}
        </div>
        <div className="w-full h-full lg:w-3/12 rounded">
          <Img
            alt={title}
            fluid={image.childImageSharp.fluid}
            className="flex rounded items-center justify-center m-auto lg:max-w-[300px]"
          />
        </div>
      </div>
    </div>
  );
};

const WhatsMore = () => {
  const { free, use, help } = useStaticQuery(graphql`
    query {
      free: file(relativePath: { eq: "why_free.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      use: file(relativePath: { eq: "why_use.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      help: file(relativePath: { eq: "why_help.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section id="why">
      <div className="container px-4 py-8 lg:py-24">
        <h1 className="text-secondary-main pb-8">
          Miért éri meg regisztrálni?
        </h1>
        <Card title="Ingyenes segítség a családoknak" image={free}>
          A platform teljesen ingyenes, nincsenek rejtett költségek és a
          családosokat a várandósság időszakától segíti. A program minden magyar
          családot segíti legyen bárhol a világban.
        </Card>
        <Card
          reverse
          title="Használja a tananyagokat a mindennapokban"
          image={use}
        >
          Készüljön megbízható, hiteles tananyagokkal várandósságára, gyermeke
          születésére és a gyermeknevelésre. Más információra is szüksége lenne?
          Jelezzen vissza és mi elkészítjük.
        </Card>
        <Card
          title="Segítség és támogatás partnereink vagy más családok körében"
          image={help}
          button={
            <Link
              to="partners"
              title="Partnerek"
              className="btn bg-apricot-700 text-white w-full lg:w-auto"
            >
              Partnereink
            </Link>
          }
        >
          Nem tudja merre induljon? Egyedül érzi magát problémáival? Forduljon
          partnereinkhez bizalommal, találjon segítséget gyorsan.
        </Card>
      </div>
    </section>
  );
};

export default WhatsMore;
