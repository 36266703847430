import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const Donate = () => {
  const { family } = useStaticQuery(graphql`
    query {
      family: file(relativePath: { eq: "family_pic.png" }) {
        childImageSharp {
          fluid(maxWidth: 356, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section id="process">
      <div className="container px-4 py-12 flex flex-col items-center">
        <div className="max-w-[800px] flex flex-col lg:flex-row justify-center items-center">
          <Img
            alt="Vedd fel velünk a kapcsolatot!"
            fluid={family.childImageSharp.fluid}
            style={{ maxWidth: 360 }}
            className="w-full mb-4 lg:mb-0 lg:mr-12"
          />
          <div className="w-full">
            <h3 className="pb-8 text-primary-800">
              Adományozzon a magyar családok megsegítésének érdekében!
            </h3>

            <p className="pb-8 text-lg text-primary-700">
              Személyes küldetésével, értékeivel megegyezőnek találja az Evita
              program által kitűzött célokat?
            </p>

            <div className="lg:max-w-[240px]">
              <a href="/donate" className="btn-primary w-full">
                További információk
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Donate;
