import React from 'react';
import SwiperView from '../common/SwiperView';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ListItem = (props) => {
  const { item } = props;
  const { name, image, text, email, web, tel } = item;

  return (
    <div className="h-full bg-white rounded-3xl p-8">
      {image && (
        <Img
          alt={name}
          fluid={image.childImageSharp.fluid}
          className="object-cover flex-none max-h-[80px]"
          imgStyle={{ objectFit: 'contain', objectPosition: 'left' }}
        />
      )}

      <h4 className="text-primary-700 pt-8 pb-3">{name}</h4>
      {text && <p className="text-secondary-700 text-lg pb-3">{text}</p>}
      {email && (
        <>
          <p className="text-secondary-700 text-sm uppercase">E-mail cím</p>
          <p className="text-primary-600 text-lg pb-3">{email}</p>
        </>
      )}
      {web && (
        <>
          <p className="text-secondary-700 text-sm uppercase">Weboldal</p>
          <div className="pb-3">
            <a
              href={web}
              target="_blank"
              rel="noreferrer"
              className="text-primary-600 text-lg"
            >
              {web}
            </a>
          </div>
        </>
      )}
      {tel && (
        <>
          <p className="text-secondary-700 text-sm uppercase">Telefonszám</p>
          <p className="text-primary-600 text-lg pb-3">{tel}</p>
        </>
      )}
    </div>
  );
};

export default function PartnerOffers() {
  const {
    defaultImg,
    erosz,
    ngysz,
    bkfk,
    artis,
    oefi,
    szent_margit,
    mero,
    kfa,
  } = useStaticQuery(graphql`
    query {
      defaultImg: file(relativePath: { eq: "companies/default.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      erosz: file(relativePath: { eq: "companies/erosz.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ngysz: file(relativePath: { eq: "companies/ngysz.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bkfk: file(relativePath: { eq: "companies/bkfk.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      artis: file(relativePath: { eq: "companies/artis.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      oefi: file(relativePath: { eq: "companies/oefi.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      szent_margit: file(relativePath: { eq: "companies/szent_margit.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mero: file(relativePath: { eq: "companies/mero.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      kfa: file(relativePath: { eq: "companies/kfa.png" }) {
        childImageSharp {
          fluid(maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const partners = [
    {
      name: 'Erdélyi Rezidens Orvosok Szövetsége',
      text: 'Az Erdélyi Rezidens Orvosok Szövetsége 2014-ben alakult az erdélyi rezidens orvosok érdekvédelmi szervezeteként. Legfőbb célunk az Erdélyben frissen végzett orvos és rezidens közösség kiépítése és összetartása.',
      email: 'rezidens.ms@gmail.com',
      web: 'https://www.facebook.com/rezidensszovetseg',
      tel: '',
      image: erosz,
    },
    {
      name: 'Perinatális tanácsadók',
      text: 'A perinatális szaktanácsadó képzés 2007 óta része az ELTE Pedagógiai és Pszichológiai Kar szakirányú továbbképzés kínálatának. A perinatális tudomány holisztikus, rendszerszemléletű megközelítése egyetlen egységes, transzgenerációs folyamatként kezeli a szülés-születés időszakát a fogantatástól a következő nemzedék megszületéséig, melyben a vonatkozó szakmák interdiszciplináris együttműködése kívánatos.',
      email: 'mpppot@gmail.com',
      web: 'https://www.mpppot.hu/perinatalis-tudomanyok/perinatalis-szaktanacsadok',
      tel: '',
      image: defaultImg,
    },
    {
      name: 'Nemzetközi Gyermekmentő Szolgálat',
      text: 'A Nemzetközi Gyermekmentő Szolgálat célja a fizikailag, értelmileg sérült, az árva és szociálisan hátrányos helyzetű gyermekek felkarolása, életkörülményeik javítása, gyermekintézmények segítése.',
      email: 'ngysz@gyermekmento.hu',
      web: 'https://www.gyermekmento.hu',
      tel: '+36 (1) 475-7000',
      image: ngysz,
    },
    {
      name: 'PromoBox Kft.',
      text: 'A PromoBox Kft. 2010-ben alakult azzal a céllal, hogy a marketingkommunikáció egyik speciális eszközének segítségével, ajándékcsomagokkal kedveskedjen, és információs kiadványokkal segítsen a gyermeküket világra hozó Édesanyáknak. Legfontosabb célkitűzésünk, hogy az édesanyákat, édesapákat és a család minden tagját segítsük az előttük álló úton és elhivatottságunkkal, türelmünkkel támogassuk őket ebben a kérdésekkel teli időszakban.',
      email: 'info@promobox.hu',
      web: 'https://promobox.hu',
      tel: '',
      image: defaultImg,
    },
    {
      name: 'ARTIS Centrum',
      text: 'Fő célunkként tűztük ki a Szlovákia területén élő sajátos nevelési igényű gyermekek, felnőttek képességfejlesztését, nevelését, és a normál fejlődésmenetű, valamint tehetséges gyermekek tehetséggondozását. Nemcsak azokkal a gyermekekkel foglalkozunk, akiknél már kialakult egy bizonyos probléma, a megelőzésre és a tehetséggondozásra is nagy hangsúlyt fektetünk.',
      email: 'info@artiscentrum.sk',
      web: 'https://www.artiscentrum.sk',
      tel: '+421 944 577 257',
      image: artis,
    },
    {
      name: 'KIKAPCS. Alapítvány',
      text: 'A KIKAPCS. Alapítvány küldetése, hogy kedvezményes kikapcsolódást és közösséget biztosítson olyan családok számára, akik sajátos nevelési igényű (SNI) gyermeket nevelnek.',
      email: 'info@kikapcs.eu',
      web: 'https://www.kikapcs.eu/en',
      tel: '+36 30 524 90 50',
      image: defaultImg,
    },
    {
      name: 'Meliorat.io',
      text: 'A Meliorato társulás a speciálpedagógia és a pszichológia területén tevékenykedő szakemberek összefogásával jött létre 2017-ben első magyar nyelvű fejlesztőközpontként Pozsonyban, mely személyre szabott, anyanyelven történő fejlesztő foglalkozásokat és terápiákat kínál. A központ elsődleges küldetése a komplex speciálpedagógiai fejlesztés, emellett egyéni és csoportos foglalkozásokat és terápiákat is tartunk.',
      email: 'info@meliorat.eu',
      web: 'https://meliorat.io',
      tel: '09 05 242 940',
      image: defaultImg,
    },
    {
      name: 'Kiwi Skill Kft.',
      text: 'A Kiwi fejlesztő központ főként gyermekek fejlesztésével foglalkozik már 2 hónapos kortól, különböző módszerek, terápiák segítségével. A foglalkozásokat, üléseket tapasztalt szakemberek: gyógypedagógus, pszichológus, logopédus, konduktor és mozgásterapeuta vezetik.',
      email: 'info@kiwiskill.sk',
      web: 'https://www.kiwiskill.sk/hu',
      tel: '+421 915 579 471',
      image: defaultImg,
    },
    {
      name: 'Budapesti Korai Fejlesztő Központ',
      text: 'Fő tevékenységünk a kora gyermekkori intervenció: komplex diagnosztikai vizsgálat, egyénre szabott fejlesztő és terápiás program, egyéb szociális segítség.',
      email: 'titkarsag@koraifejleszto.hu',
      web: 'https://www.koraifejleszto.hu',
      tel: '+36-1-363-02-70',
      image: bkfk,
    },
    {
      name: 'Meddőségi, Reprodukciós és Örökbefogadási Szaktanácsadó Alapítvány',
      text: '',
      email: '',
      web: '',
      tel: '',
      image: mero,
    },
    {
      name: 'Óbudai Szent Margit Rendelőintézet Non-profit Kft.',
      text: '',
      email: '',
      web: 'https://www.szentmargitrendelo.hu',
      tel: '+36 1 388 9180',
      image: szent_margit,
    },
    {
      name: 'Humanikon Egyesület',
      text: '',
      email: '',
      web: '',
      tel: '',
      image: defaultImg,
    },
    {
      name: 'Kismackó Alapítvány',
      text: 'A nonprofit KisMackó Alapítványt 2015-ben a PromoBox Kft. alapította, hogy a korábban is folyamatos CSR-tevékenységünket szervezett keretek között folytathassuk. Feladatunknak tekintjük, hogy hozzájáruljunk a koraszülött kisbabák, a rossz körülmények között élő várandós kismamák és kisgyermekes családok, a beteg kisgyermekek életének könnyebbé tételéhez, illetve a kórházak eszközökkel történő támogatásához, a szülésznők és csecsemőkkel foglalkozó szakdolgozók továbbképzéséhez.',
      email: '',
      web: '',
      tel: '',
      image: defaultImg,
    },
    {
      name: 'Kézen Fogva Alapítvány',
      text: 'Célunk, hogy a fogyatékos emberek életük minden fázisában esélyhez jussanak képességeik fejlesztésében és a megtanultak használatában - születéstől, a tanulás és munka évein át, életük végéig. A fogyatékos egyén áll a középpontban, de a szűk családtól, a segítőkön és intézményeken át a széles körben vett társadalomig mindenkihez szólnak szakmai programjaink.',
      email: 'kezenfogva@kezenfogva.hu',
      web: 'https://kezenfogva.hu',
      tel: '+36 1 215 52 13',
      image: kfa,
    },
    {
      name: 'Nagycsaládosok Országos Egyesülete',
      text: 'A NOE legjellemzőbb tevékenysége a családok érdekképviselete és -védelme. A családok érdekének a védelme igen széles körűen értelmezendő feladat, felöleli az emberi életnek szinte minden területét, ebből következően igen szerteágazó kérdésekkel és problémákkal foglalkozunk nap mint nap.',
      email: 'noe@noe.hu',
      web: 'https://noe.hu',
      tel: '+36 1 235 0945',
      image: defaultImg,
    },
  ];

  return (
    <section id="references" className="bg-blue-100">
      <div className="container px-0 lg:px-4 py-8 lg:py-24">
        <h1 className="text-secondary-main pb-8 px-4 lg:px-0">Partnereink</h1>
        <SwiperView
          items={partners}
          renderer={(item) => <ListItem item={item} />}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 16,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        />
      </div>
    </section>
  );
}
