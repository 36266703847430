import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { APP_REGISTER_URL, SHOW_APP_URL } from '../../constants';

const Register = () => {
  const { register, registerMobile } = useStaticQuery(graphql`
    query {
      register: file(relativePath: { eq: "illu_register.png" }) {
        childImageSharp {
          fluid(maxWidth: 725, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      registerMobile: file(relativePath: { eq: "illu_register_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 997, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className="bg-secondary-600" id="join">
      <div className="container px-4 py-8 lg:py-12 flex flex-col items-center">
        <div className="max-w-[800px] flex my-4 flex-col-reverse lg:flex-row justify-center items-center">
          <div className="lg:w-7/12">
            <h2 className="text-white pb-8">
              Regisztrálj, hogy tudatosabb szülővé válhass!
            </h2>

            <p className="text-white text-lg pb-8">
              Hitelesített, gyakorlati segédletek várandósok, kismamák, apukák
              számára a családtervezéstől egészen az iskolaérettségig.
            </p>

            {SHOW_APP_URL && (
              <div className="lg:max-w-[240px]">
                <a
                  href={APP_REGISTER_URL}
                  target="_blank"
                  className="btn w-full bg-white text-black"
                >
                  Regisztráció
                </a>
              </div>
            )}
          </div>
          <Img
            alt="Vedd fel velünk a kapcsolatot!"
            fluid={register.childImageSharp.fluid}
            className="hidden lg:block w-5/12 ml-20 mb-0"
            style={{ maxWidth: 360 }}
          />
          <Img
            alt="Vedd fel velünk a kapcsolatot!"
            fluid={registerMobile.childImageSharp.fluid}
            className="w-full mb-8 lg:hidden"
            style={{ maxWidth: 360 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Register;
