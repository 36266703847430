import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SwiperView from '../common/SwiperView';

const ListItem = (props) => {
  const { item, quoteImg } = props;
  const { name, image, text } = item;

  return (
    <div className="h-full bg-blue-100 rounded-3xl p-8">
      <div className="flex flex-row items-center justify-between">
        <Img
          className="object-cover flex-none min-w-[80px] min-h-[80px] w-[80px] h-[80px] rounded-3xl"
          alt={name}
          fluid={image.childImageSharp.fluid}
        />
        <Img
          className="min-w-[60px] min-h-[60px] w-[60px] h-[60px]"
          alt={name}
          fluid={quoteImg.childImageSharp.fluid}
        />
      </div>
      <h2 className="text-blue-800 pt-8 pb-5">{name}</h2>
      <p className="text-blue-700 text-lg italic">{text}</p>
    </div>
  );
};

const Testimonial = () => {
  const { maria, oszkar, margit, quote } = useStaticQuery(graphql`
    query {
      maria: file(relativePath: { eq: "maria.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      oszkar: file(relativePath: { eq: "oszkar.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      margit: file(relativePath: { eq: "margit.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      quote: file(relativePath: { eq: "quote.png" }) {
        childImageSharp {
          fluid(maxWidth: 60, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  const images = [
    {
      name: 'Tasnádi Mária',
      text: '“A platform teljesen ingyenes, nincsenek rejtett költségek és a családosokat a várandósság időszakától segíti. A program minden magyar családot segíti legyen bárhol a világban.”',
      image: maria,
    },
    {
      name: 'Kovács Oszkár',
      text: '“Nagyon sokat segített a családnak eligazodni a korai nehézségek során, a partnereken keresztül pedig segítséget is hamar találhattunk.”',
      image: oszkar,
    },
    {
      name: 'Horvátné Rácz Margit',
      text: '“Nekem különösen a gyermeknevelés és gyógypedagógia során hozott kihívásokat az élet, de az Evita gyakorlati anyagaival sok mindent otthon, magamtól is könnyen meg tudtam oldani. Szükség esetén a partnerek szakembereihez is bátran fordulhattam, nagyon segítőkészek voltak.”',
      image: margit,
    },
  ];

  return (
    <section id="references">
      <div className="container px-0 lg:px-4 py-8 lg:py-24">
        <h1 className="text-secondary-main pb-8 px-4 lg:px-0">
          Akiknek már bevált
        </h1>
        <SwiperView
          items={images}
          renderer={(item) => <ListItem item={item} quoteImg={quote} />}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 16,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
        />
      </div>
    </section>
  );
};

export default Testimonial;
