import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';

const Card = ({ className, children }) => (
  <div
    className={clsx(
      'bg-blue-50 rounded-xl p-6 border border-blue-200',
      className
    )}
  >
    {children}
  </div>
);

const DonateDetails = () => {
  const { family } = useStaticQuery(graphql`
    query {
      family: file(relativePath: { eq: "family_pic.png" }) {
        childImageSharp {
          fluid(maxWidth: 368, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className="container max-w-[800px] px-4 lg:px-0 pt-4 pb-8 lg:pb-44">
        <h1 className="py-6 lg:py-12">
          Adományozzon a magyar családok megsegítésének érdekében!
        </h1>
        <div className="flex flex-col-reverse lg:flex-row justify-center items-center pb-4 lg:pb-8">
          <Card className="self-stretch w-full">
            <h4 className="pb-4 text-primary-700">Magánszemélyként</h4>

            <p className="pb-4 text-lg text-primary-700 font-bold">
              Személyes küldetésével, értékeivel megegyezőnek találja az Evita
              program által kitűzött célokat?
            </p>

            <p className="text-lg text-primary-700">
              A magánszemélyek részéről beérkező adományok fogadására még
              jelenleg nincs kialakított folyamatunk, kérjük iratkozzon fel
              hírlevelünkre és értesítjük a folyamatos fejlesztésekkel
              kapcsolatban.
            </p>
          </Card>
          <Img
            alt="Vedd fel velünk a kapcsolatot!"
            fluid={family.childImageSharp.fluid}
            style={{ maxWidth: 360 }}
            className="w-full max-h-[360px] rounded-xl ml-0 lg:ml-8 mb-4 lg:mb-0"
          />
        </div>
        <Card className="w-full">
          <h4 className="pb-4 text-primary-700">
            Céges vagy szakmai szponzorként
          </h4>
          <p className="pb-2 text-lg text-primary-700">
            A családok egészsége és jóléte közös érdekünk, munkaadóként vegyen
            részt céges partnerként és támogassa a családos munkavállalókat!
          </p>
          <p className="pb-2 text-lg text-primary-700">
            Ha nagyobb összeggel, tárgyi adománnyal, vagy
            természetbeni juttatásával formájában kívánja támogatni az Evita
            küldetését lehetőségeinkhez mérten egy kölcsönösen előnyös
            együttműködés formájában igyekszünk meghálálni felajánlásukat.
          </p>
          <p className="text-lg text-primary-700">
            A támogatásért cserébe, az Evita részéről felajánlott
            csereszolgáltatásokról vagy egyéb, az Ön/Önök igényei alapján
            felmerülő további lehetőségekről kérjen részletesebb tájékoztatást a{' '}
            <a
              href="mailto:titkarsag@mik.ma"
              className="font-bold text-primary-main hover:underline"
            >
              titkarsag@mik.ma
            </a>{' '}
            e-mail címen.
          </p>
        </Card>
      </div>
    </section>
  );
};

export default DonateDetails;
