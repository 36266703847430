import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { APP_REGISTER_URL, SHOW_APP_URL } from '../../constants';

const Hero = () => {
  const { bgHero, bgHeroMobile } = useStaticQuery(graphql`
    query {
      bgHero: file(relativePath: { eq: "bg_hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1446, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bgHeroMobile: file(relativePath: { eq: "bg_hero_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1372, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const sources = [
    {
      // Mobile
      sizes: '(max-width: 1280px) 100vw, 1280px',
      media: `(max-width: 1280px)`,
    },
    {
      // Deskttop
      ...bgHero.childImageSharp.fluid,
      sizes: '(min-width: 1280px) 100vw, 1280px',
      media: `(min-width: 1280px)`,
    },
  ];

  return (
    <BackgroundImage
      tag="section"
      preserveStackingContext
      fluid={sources}
      style={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}
      backgroundColor="rgba(233, 243, 254, 0.4)"
      id="intro"
    >
      {/* 100vh - 4.4rem margin top (defined in Layout.js) */}
      <div className="container px-4 lg:h-[calc(100vh-4.4rem)] flex flex-col justify-center items-center xl:items-start">
        <Img
          fluid={bgHeroMobile.childImageSharp.fluid}
          alt="Evita"
          className="w-full visible xl:hidden mb-6 my-4"
          imgStyle={{ objectFit: 'contain' }}
        />
        <div className="max-w-[28rem]">
          <h1 className="text-primary-700">Evita családtámogató platform</h1>
          <h4 className="text-secondary-600 pt-4 pb-8">
            Nőgyógyászattól a gyógypedagógiáig nálunk mindent megtalál!
          </h4>

          <p className="text-[#3A3A3A] text-lg pb-8">
            Hitelesített, gyakorlati segédletek várandósok, kismamák, apukák
            számára a családtervezéstől egészen az iskolaérettségig.
          </p>

          {SHOW_APP_URL && (
            <a
              href={APP_REGISTER_URL}
              target="_blank"
              className="btn-primary text-center mb-4 xl:mb-0 w-full lg:w-auto"
            >
              Regisztráció
            </a>
          )}
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Hero;
