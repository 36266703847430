import React from 'react';
import { APP_REGISTER_URL, SHOW_APP_URL } from '../../constants';

const Card = ({ number, title, color, children, button }) => {
  const Number = () => (
    <div className={`bg-${color}-400 rounded-xl px-6 py-4 mr-6`}>
      <h2 className={`text-${color}-600`}>{number}.</h2>
    </div>
  );
  const Title = () => <h2 className="text-secondary-800">{title}</h2>;

  const hasButton = !!button;

  return (
    <div
      className={`w-full bg-${color}-200 rounded-3xl self-center lg:self-stretch p-4 lg:p-12 mr-0 lg:mr-6 mb-6 text-white`}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row items-center">
          <Number />
          <Title />
        </div>
        <div className={`text-${color}-700 text-lg pt-8`}>{children}</div>
        {hasButton ? (
          <div className="mt-6 lg:mt-auto lg:pt-6 lg:self-start">{button}</div>
        ) : null}
      </div>
    </div>
  );
};

const Introduction = () => {
  return (
    <section id="process">
      <div className="container px-4 py-8 lg:py-24">
        <h1 className="text-secondary-main pb-8">Hogyan működik?</h1>
        <div className="flex justify-center flex-col lg:flex-row flex-no-wrap">
          <Card
            number="1"
            title="Regisztráció"
            color="apricot"
            button={
              SHOW_APP_URL ? (
                <a
                  href={APP_REGISTER_URL}
                  target="_blank"
                  className="btn bg-apricot-700 text-white w-full lg:w-auto"
                >
                  Regisztráció
                </a>
              ) : undefined
            }
          >
            Regisztráljon ingyenesen és érjen el szakemberek által hitelesített
            segédanyagokat!
          </Card>
          <Card number="2" title="Tanulás a családalapításról" color="green">
            A tananyagok a családtervezéstől egészen a gyermek iskolaérettségéig
            támogatják a családosokat. Szakembereink az Önök visszajelzései
            alapján folyamatosan fejlesztik a tananyagot. Segítsen nekünk
            véleményével Ön is!
          </Card>
        </div>
        <div className="flex justify-center flex-col lg:flex-row flex-no-wrap">
          <Card number="3" title="Partneri hálózat" color="secondary">
            Problémát észlel gyermeke fejlődésével kapcsolatban vagy segítségre
            lenne szüksége? Partnereink elérhetőségei megtalálhatóak a
            regisztráció után, forduljon hozzájuk bátran.
          </Card>
          <Card number="4" title="Események, segítség" color="blue">
            A segédanyagok mellett vegyen részt érdekes előadásokon online vagy
            élőben lakhelyéhez közel. Nem talál valamit az oldalon? Keresse a
            megoldást a “Segítség” fülön.
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
