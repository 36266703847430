import React, { useState } from 'react';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';

export default function SwiperView({ items, renderer, breakpoints }) {
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const initialSlide = Math.floor(items.length / 2);

  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, A11y]}
        navigation={{ prevEl, nextEl }}
        initialSlide={initialSlide}
        breakpoints={breakpoints}
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.name ?? index} className="h-auto">
            {renderer(item)}
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        ref={(node) => setNextEl(node)}
        className="btn-slide-nav right-[-24px] swiper-button-disabled"
      >
        <ChevronRight width="16" height="16" className="fill-white" />
      </button>
      <button
        ref={(node) => setPrevEl(node)}
        className="btn-slide-nav left-[-24px] swiper-button-disabled"
      >
        <ChevronLeft width="16" height="16" className="fill-white" />
      </button>
    </div>
  );
}
